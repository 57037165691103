<template>
  <div class="tr_box">
    <div class="tr_list" v-loading="loading">
      <div class="title">
        Tools & Resources
      </div>
      <div class="create-link-button">
        <el-button @click="onCreateTool" class="primary-button">+ Add Link</el-button>
      </div>
      <ToolsResourcesTable :data="data" :total="total" @editTool="editTool" @pagesChanged="pagesChanged">
      </ToolsResourcesTable>
    </div>
    <ToolsResourcesDialog ref="show" :title="title" :contect="toolInfo"></ToolsResourcesDialog>
  </div>
</template>
<script>
import { apiGetExternalTools, apiUpdateExternalTool } from "../../API/api";
import ToolsResourcesTable from "./component/toolsResourcesTable.vue";
import { Minixs } from "../../js/mixins";
import ToolsResourcesDialog from "./component/toolsResourcesDialog.vue";
export default {
  mixins: [Minixs],
  data() {
    return {
      title: "Add New Tool",
      search: "",
      agentEdit: false,
      toolInfo: {},
      loading: false,
      total: 1, 
      data: [], 
      currentPage: 1, 
      limit: 25,
      count: 0,
    };
  },
  components: { ToolsResourcesTable, ToolsResourcesDialog },
  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
    fuzzyQuery() {
      return {
        limit: this.limit,
        offset: this.offset,
        search: this.search,
        user_type: ["pm", "admin"],
      };
    },
  },
  methods: {
    editTool(item) {
      this.title = "Manage Link";
      this.toolInfo = item;
      this.$refs.show.showTool = true;
    },
    onCreateTool() {
      this.toolInfo = null;
      this.title = "Add Link";
      this.$refs.show.showTool = true;
    },
    clickEdit() {
      this.loading = true;
      apiUpdateExternalTool(this.toolInfo.user_id, this.toolInfo).then((res) => {
        this.getToolList();
        this.agentEdit = false;
      }).finally(() => {
        this.loading = false;
      });
    },
    getToolList() {
      this.loading = true;
      apiGetExternalTools(this.fuzzyQuery)
        .then((res) => {
          this.data = res.results;
          this.total = res.count;
          this.count = res.count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    pagesChanged(number) {
      this.currentPage = number;
      this.getToolList();
    },
  },
  created() {
    this.getToolList();
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/button.scss";

.tr_box {

  .title {
    font-weight: 400;
    font-size: 24px;
    line-height: 28.13px;
    text-align: left;
    color: #36425D;
    margin-bottom: 46px;
  }

  .tr_list {
    padding: 50px 250px 50px 50px;
    background-color: #fff;
    border-radius: 16px;

    .create-link-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

    .edit_btn {
      color: #5f99aa;
    }
  }

  @media (max-width:1920px) {
    .tr_list {
      padding: 50px;

    }

  }

  @media (max-width:772px) {
    .tr_list {
      padding: 5px;

    }

  }

}
</style>
