<template>
  <div class="link-table">
    <div class="table">
      <el-table :data="this.data" style="width: 100%" :cell-style="{padding: '10px 0px'}">
        <el-table-column label="LOGO" prop="date" min-width="50px">
          <template slot-scope="props">
            <p class="link_avatar" @click="handleEdit(props.row)">
              <img v-if="props.row.image_url" :src="props.row.image_url" alt />
              <img v-else src="../../../assets/img/placeholder.png" alt />
            </p>
          </template>
        </el-table-column>
        <el-table-column label="TITLE" min-width="70px">
          <template slot-scope="props">
            <p @click="handleEdit(props.row)"> {{ props.row.title }} </p>
          </template>
        </el-table-column>
        <el-table-column label="LINK" min-width="120px">
          <template slot-scope="scope">
            <p @click="handleEdit(scope.row)">{{ scope.row.link }}</p>
          </template>
        </el-table-column>

        <el-table-column label="ACTIVATION" min-width="50px">
          <template slot-scope="scope">
            <el-switch @change="changeActivateValue($event, scope.$index, scope.row)" v-model="scope.row.is_active"
              active-color="#678993" inactive-color="#737A8C"></el-switch>
          </template>
        </el-table-column>

        <el-table-column width="40">
          <template slot-scope="scope">
            <i class="el-icon-edit" @click="handleEdit(scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column width="40">
          <template slot-scope="scope">
            <i class="el-icon-delete" @click=""></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Page :total="total" :pageSize="25" @number="pagesChanged"></Page>
  </div>
</template>
<script>
import { apiUpdateExternalTool, } from "../../../API/api";
import Page from "../../common/Paging";
export default {
  components: { Page },
  props: ["data", "total"],
  methods: {
    handleEdit(item) {
      this.$emit("editTool", item);
    },
    pagesChanged(number) {
      this.$emit("pagesChanged", number)
    },
    changeActivateValue(val, index, row) {
      let obj = {
        is_active: row.is_active,
      };
      apiUpdateExternalTool(row.id, obj).then((res) => {
        this.$message.success("success");
      }).catch((error) => {
        let a = this.data[index];
        a.is_active = !val;
        this.$set(this.data, index, a);
        this.$message.error(error.response.data.detail ? error.response.data.detail : "fail");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/table.scss";

.link-table {
 
  .link_avatar {
    width: 40px;
    height: 40px;
    margin: 0;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }

  .type-box {
    border: 1px solid #e1e9ef;
    background-color: #f4f9fc;
    padding: 4px 20px;
    border-radius: 2px;
    display: inline-block;
    color: #38425b;
  }

  .el-icon-edit-outline {
    font-size: 22px;
    color: #678993;
  }
}
</style>