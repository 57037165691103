<template>
  <div class="tr-dialog">
    <el-dialog :title="title" :visible.sync="showTool" @open="onOpen">
      <el-form :model="data" ref="data">
        <div class="tr-content ">
          <div class="tr-input">
            <el-form-item>
              <p class="tr-tip">Link Title</p>
              <el-input v-model="data.title" placeholder="Enter link title" />
            </el-form-item>
            <el-form-item>
              <p class="tr-tip"> Link Address</p>
              <el-input v-model="data.link" placeholder="Enter link address" />
            </el-form-item>
          </div>
          <div class="tr-photo">
            <p class="tr-tip"> Icon</p>
            <div class="upload-image">
              <img :src="selectedFile? img: data.image_url ? data.image_url : img" class="tr-img" />
              <div class="upload-action">
                <u class="p-delete" @click="onDeleteImage">Delete Photo</u>
                <div class="btn-upload">
                  <el-upload action="demo" :show-file-list="false" :http-request="onUploadImage" v-loading="loadingImg"
                    element-loading-spinner="el-icon-loading">
                    <el-button @click="onUploadImage">Upload</el-button>
                  </el-upload>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn">
          <div class="btn-cancel">
            <el-button plane class="light-blue-button" @click="showTool = false">Cancel </el-button>
          </div>
          <div class="btn-save">
            <el-button class="primary-button" @click="onSubmit('data')" :loading="loading">Save </el-button>
          </div>

        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
function keepTwoDecimal(num) {
  return Math.round(parseFloat(num) * 100) / 100;
}
import {
  apiCreateExternalTool,
  apiGetExternalTool,
  apiUpdateExternalTool,
  apiUploadImage,
} from "../../../API/api";
import { Minixs } from "../../../js/mixins";
export default {
  mixins: [Minixs],
  components: {},
  props: ["title", "contect"],
  data() {
    return {
      showTool: false,
      id: null,
      data: {
        title: "",
        link: "",
        image_url: "",
        is_active: false,
      },
      loadingImg: false,
      loading: false,
      commission_percentage: 50,
      img: require("../../../assets/img/placeholder.png"),
      selectedFile: null,
    };
  },
  methods: {
    onOpen() {
      if (this.title === "Add Link") {
        this.data = {
          title: "",
          link: "",
          image_url: "",
          is_active: false,
        };
        this.id = "";
        this.selectedFile = null;
        this.img = require("../../../assets/img/placeholder.png");
      } else {
        this.data.title = this.contect.title;
        this.data.link = this.contect.link;
        this.data.image_url = this.contect.image_url;
        this.data.is_active = this.contect.is_active;
        this.id = this.contect.id;
        console.log("image url ", this.data.image_url)
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          if (this.selectedFile) formData.append("file", this.selectedFile);
          formData.append("title", this.data.title);
          formData.append("link", this.data.link);
          if (!this.selectedFile && !this.data.image_url) formData.append("image_url", this.data.image_url);
          this.loading = true;
          if (this.id) {
            apiUpdateExternalTool(this.id, formData).then((res) => {
              this.$message.success("Modification succeeded");
              this.$router.go(0);
            }).catch((err) => {
              this.$message.error("request was aborted. " + err.response.data.detail);
            }).finally(() => {
              this.loading = false;
              this.showTool = false;
            });
          } else {
            apiCreateExternalTool(formData).then((res) => {
              this.$message.success("Created successfully");
              this.$router.go(0);
            }).catch((err) => {
              this.$message.error("request was aborted. " + err.response.data.detail);
            }).finally(() => {
              this.loading = false;
              this.showTool = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    onUploadImage(file) {
      console.log(file)
      if (file.file) {
        this.img = URL.createObjectURL(file.file)
        this.selectedFile = file.file;
      }
      else {
        console.log("invalid file selected")
      }

    },
    onDeleteImage() {
      this.img = require("../../../assets/img/placeholder.png");
      this.data.image_url = "";
      this.image_url = null;
      this.selectedFile = null;
    },
    initEdit() {
      apiGetExternalTool(this.$route.params.id).then((res) => {
        this.data = res;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/dialog.scss";
@import "../../../css/button.scss";

.tr-dialog {
  /deep/ .el-input__inner {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid #D8E2E4;
    border-radius: 0;
  }

  /deep/ .el-dialog {
    width: 600px;
    border-radius: 14px;
  }

  /deep/ .el-dialog__title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: #1C4A5E;
  }

  /deep/ .el-dialog__header {
    padding-left: 40px;
    padding-top: 41.91px;
    padding-bottom: 15.56px;
    border-radius: 14px;
  }

  /deep/ .el-dialog__body {
    padding-left: 40px;
  }

  /deep/ .el-dialog .el-dialog__header::after {
    width: 85%;
    left: 40px;
  }

  .tr-tip {
    width: 100%;
    text-align: left;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    color: #43555A;
  }

  .tr-input {
    width: 70%;
  }

  .tr-content {
    margin-top: 20px;
    align-items: flex-start;

    .upload-image {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 70%;

      .upload-action {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        .p-delete {
          color: #F53D6B;
          font-size: 14px;
          font-weight: 400;
          line-height: 19.12px;
          text-align: left;
          cursor: pointer;
        }
      }

      .tr-img {
        width: 165px;
        height: 103px;
        border-radius: 4px;
        object-fit: cover;
      }
    }
  }

  .btn-upload {

    .el-button {
      width: 132px;
      height: 52px;
      background-color: #1C4A5E;
      color: white;
      font-size: 15px;
      line-height: 20.49px;
      font-weight: 700;
      padding: 16px 40px;
    }
  }

  .btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 59px;
    margin-right: 24px;
    gap: 20px;

    .btn-save {
      .el-button {
        width: 141px;
        height: 55px;
        padding: 16px 40px;
        background-color: #1C4A5E;
        color: white;
        font-size: 15px;
        line-height: 20.49px;
        font-weight: 700;
      }
    }

    .btn-cancel {
      .el-button {
        width: 141px;
        height: 55px;
        padding: 16px 40px;
        background-color: white;
        color: #F53D6B;
        font-size: 15px;
        line-height: 20.49px;
        font-weight: 700;
        border-color: #F53D6B;
      }
    }
  }

  @media (max-width: 992px) {
    /deep/ .el-dialog {
      width: 95%;
    }
  }

  @media (max-width : 550px) {
    .upload-image {
      gap: 20px
    }

    .tr-img {
      width: 120px;
      height: 80px;
    }

    /deep/ .el-dialog__header {
      padding-left: 20px;

    }

    /deep/ .el-dialog__body {
      padding-left: 20px;
    }

    /deep/ .el-dialog .el-dialog__header::after {
      width: 85%;
      left: 20px;
    }

    .btn {
      justify-content: center;
      left: 0;
      margin-right: 0;
    }
  }
}
</style>